<template>
    <ContentLayout pad>
        <HostesseElement v-if="hasAccess" button @update="fetch"></HostesseElement>
        <v-row align="center" class="text-md-h3 text-sm-h4 text-h4 mb-8">
            <v-col cols="2" md="4" class="text-right">
                <v-btn icon x-large tile @click="dow--" :disabled="dow == 1 || fetching">
                    <v-icon size="60">mdi-chevron-left</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="8" md="4" class="text-center">
                <span class="secondary-font primary--text text-uppercase">{{ $t("dow." + dow) }}</span>
            </v-col>
            <v-col cols="2" md="4" class="text-left">
                <v-btn icon x-large tile @click="dow++" :disabled="dow == 7 || fetching">
                    <v-icon size="60">mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="fetching">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate size="80"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="items.length > 0" justify="center">
            <v-col cols="12" sm="6" md="5" lg="4" xl="3" v-for="(item, i) in items" :key="i">
                <HostesseElement :item="item" @update="fetch" origin="schedule"></HostesseElement>
            </v-col>
        </v-row>
        <div
            v-else
            class="text-center secondary-font text-md-h4 text-sm-h5 text-h6"
        >
            {{ $t("no-data") }}
        </div>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import HostesseElement from "@/components/elements/Hostesse";

    export default {
        name: "schedule-view",
        components: { ContentLayout, HostesseElement },
        data: () => ({
            dow: 1,
            fetching: false,
            items: []
        }),
        watch: {
            dow() {
                this.fetch();
            }
        },
        computed: {
            hasAccess() {
                return !!this.$store.state.user && 
                    !!this.$store.state.user.type && 
                    (this.$store.state.user.type == "admin");
            }
        },
        methods: {
            fetch() {
                if(this.fetching) {
                    return;
                }
                this.fetching = true;
                this._request("hostesses/get-all", { dow: this.dow }).then(response => {
                    if(!response.items || response.items.length == 0) {
                        this.items = [];
                        return;
                    }
                    let items = response.items.sort((a, b) => a.schedule[0].start > b.schedule[0].start ? 1 : -1);
                    for(let i = 0; i < items.length; i++) {
                        items[i].availability = items[i].schedule[0].availability;
                    }
                    this.items = items;
                    //console.log(this.items);

                }).finally(() => {
                    this.fetching = false;
                });
            }
        },
        created() {
            this.dow = new Date().getDay();
            if(this.dow == 0) {
                this.dow = 7;
            }
            this.fetch();
        }
    };
</script>

<style scoped>
    .title {
        background-color: rgba(0, 0, 0, 0.3);
    }
    .action {
        z-index: 4;
    }
</style>