<template>
    <ContentLayout>
        <v-btn
            v-if="hasAccess"
            :loading="adding" :disabled="fetching || !!removing"
            @click="$refs.upload.click()"
            class="action"
            :large="$vuetify.breakpoint.smAndDown"
            :x-large="$vuetify.breakpoint.mdAndUp"
            tile outlined absolute top right
        >
            <v-icon left>mdi-plus</v-icon>{{ $t("add") }}
        </v-btn>
        <input type="file" multiple v-show="false" ref="upload" @change="add()" accept=".jpg,.jpeg,.png" />
        <v-row v-if="fetching">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate size="80" color="secondary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="images.length > 0" justify="center">
            <v-col cols="12" sm="6" md="5" v-for="(image, i) in images" :key="i">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }" tile>
                    <v-img
                        color="primary" height="400px"
                        :src="'./uploads/' + image"
                    >
                        <div class="overlay-action" @click="overlay.value = true; overlay.current = image;"></div>
                        <v-container fluid v-if="hasAccess">
                            <v-row>
                                <v-col cols="auto">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on" fab tile color="secondary"
                                                @click="updateHome(image)"
                                                :loading="updatingHome == image"
                                                class="mr-2"
                                            >
                                                <v-icon>{{ image.indexOf("_h.jpg") !== -1 ? "mdi-home" : "mdi-home-outline" }}</v-icon>
                                            </v-btn>
                                        </template>
                                        {{ $t("pages.promotions.update-home." + (image.indexOf("_h.jpg") !== -1 ? "delete" : "add")) }}
                                    </v-tooltip>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on" fab tile color="error"
                                                :disabled="!!removing && removing != image"
                                                :loading="removing == image"
                                                @click="remove(image, i)"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        {{ $t("delete") }}
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-img>
                    <!--<v-btn
                        v-if="hasAccess"
                        block x-large tile color="error"
                        :disabled="!!removing && removing != image"
                        :loading="removing == image"
                        @click="remove(image, i)"
                    >
                        <v-icon left>mdi-delete</v-icon>{{ $t("delete") }}
                    </v-btn>-->
                </v-card>
            </v-col>

            <ImageCarouselOverlay
                :value="overlay.value"
                :images="images"
                :current="overlay.current"
                path="./uploads/"
                @close="overlay.value = false"
            ></ImageCarouselOverlay>
        </v-row>
        <div v-else class="text-center secondary-font text-md-h4 text-sm-h5 text-h6">
            {{ $t("no-data") }}
        </div>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import ImageCarouselOverlay from "@/components/elements/ImageCarouselOverlay";

    export default {
        name: "promotions-view",
        components: { ContentLayout, ImageCarouselOverlay },
        data: () => ({
            fetching: false,
            adding: false,
            removing: null,
            updatingHome: null,

            images: [],

            overlay: {
                value: false,
                current: null
            }
        }),
        computed: {
            hasAccess() {
                return !!this.$store.state.user && 
                    !!this.$store.state.user.type && 
                    (this.$store.state.user.type == "admin" || 
                    this.$store.state.user.type == "promo");
            }
        },
        methods: {
            fetch() {
                if(this.fetching) {
                    return;
                }
                this.fetching = true;
                this._request("promotions/get-all").then(response => {
                    if(!response.images || response.images.length == 0) {
                        this.images = [];
                        return;
                    }
                    //console.log(response.images);
                    this.images = response.images;

                }).finally(() => {
                    this.fetching = false;
                });
            },
            add() {
                if(this.adding) {
                    return;
                }

                let files = this.$refs.upload && this.$refs.upload.files ? this.$refs.upload.files : [];
                if(files.length == 0) {
                    return;
                }

                let images = [];
                for(let i = 0; i < files.length; i++) {
                    let img = new Image();
                    img.onload = () => {
                        // Resize
                        let canvas = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        let scale = 1080 / img.height;
                        canvas.width = img.width * scale;
                        canvas.height = img.height * scale;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        let data = canvas.toDataURL(files[i].type, 0.95);
                        
                        images.push(data.split("base64,")[1]);
                    };
                    img.src = window.URL.createObjectURL(files[i]);
                }
                
                let interval = setInterval(() => {
                    if(images.length == files.length) {
                        this.adding = true;
                        this._request("promotions/add", {
                            images: images
                        }).then(response => {
                            if(response.error) {
                                this.$root.snack.show({
                                    text: response.error,
                                    icon: "mdi-alert-circle",
                                    color: "error"
                                });
                                return;
                            }
                            if(!response.images || response.images.length == 0) {
                                this.$root.snack.show({
                                    text: this.$i18n.t("error"),
                                    icon: "mdi-alert-circle",
                                    color: "error"
                                });
                                return;
                            }

                            this.images.push(...response.images);

                            this.$root.snack.show({
                                text: this.$i18n.t("success"),
                                icon: "mdi-check",
                                color: "success"
                            });

                        }).finally(() => {
                            this.adding = false;
                        });
                        clearInterval(interval);
                    }
                }, 100);
            },
            remove(image, index) {
                if(this.removing != null) {
                    return;
                }
                if(confirm(this.$i18n.t("delete-confirm"))) {
                    this.removing = image;
                    this._request("promotions/delete", {
                        filename: image
                    }).then(response => {
                        if(response.error) {
                            this.$root.snack.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                            return;
                        }

                        this.images.splice(index, 1);

                        this.$root.snack.show({
                            text: this.$i18n.t("delete-success"),
                            icon: "mdi-check",
                            color: "success"
                        });

                    }).finally(() => {
                        this.removing = null;
                    });
                }
            },

            updateHome(image) {
                //(image.indexOf("_h.jpg") !== -1 ? "delete" : "add")
                if(this.updatingHome != null) {
                    return;
                }
                this.updatingHome = image;
                this._request("promotions/update-home", {
                    filename: image
                }).then(response => {
                    if(response.error) {
                        this.$root.snack.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                        return;
                    }

                    this.$root.snack.show({
                        text: this.$i18n.t("success"),
                        icon: "mdi-check",
                        color: "success"
                    });

                    this.fetch();

                }).finally(() => {
                    this.updatingHome = null;
                });
            }
        },
        activated() {
            this.fetch();
        },
        created() {
            this.fetch();
        }
    };
</script>

<style scoped>
    .action {
        z-index: 4;
    }

    .overlay-action {
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        cursor: zoom-in;
    }
</style>
