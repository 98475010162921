<template>
    <v-dialog v-model="dialog" @input="dialog = false" max-width="400" :persistent="loading">
        <v-form ref="form" @submit.prevent @submit="login">
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <v-icon left>mdi-login</v-icon>{{ $t("session.login.text") }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field
                                :label="$t('username')"
                                v-model="form.username"
                                :disabled="loading"
                                :rules="[ $data.customRules.required ]"
                                autofocus
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                :label="$t('password')"
                                v-model="form.password"
                                type="password"
                                :disabled="loading"
                                :rules="[ $data.customRules.required ]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text tile outlined @click="dialog = false" :disabled="loading">{{ $t("close") }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn outlined tile color="primary" type="submit" :loading="loading">
                        <v-icon left>mdi-login</v-icon>
                        {{ $t("session.login.text") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        name: "login-dialog",
        props: {},
        data: () => ({
            dialog: false,
            loading: false,
            form: {
                username: null,
                password: null
            }
        }),
        methods: {
            open() {
                this.dialog = true;
                if(this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
            },
            login() {
                if(!this.$refs || !this.$refs.form || !this.$refs.form.validate()) {
                    this.$root.snack.show({
                        text: this.$i18n.t("form-validation-error"),
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                    return;
                }

                this.loading = true;
                this._request("users/login", {
                    username: this.form.username,
                    password: this.form.password
                }).then(response => {
                    if(!response.error) {
                        this.$store.commit({ type: "setUser", user: response.user });

                        this.$root.snack.show({
                            text: this.$i18n.t("session.login.success", { name: response.user.name }),
                            icon: "mdi-check",
                            color: "success"
                        });

                        this.form.username = null;
                        this.form.password = null;

                        this.dialog = false;
                    } else {
                        this.$root.snack.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            logout() {
                this._request("users/logout").then(response => {
                    if(!response.error) {
                        this.$store.commit({ type: "setUser", user: null });
                        this.$root.snack.show({
                            text: this.$i18n.t("session.logout.success"),
                            icon: "mdi-check",
                            color: "success"
                        });
                    } else {
                        this.$root.snack.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                });
            }
        }
    };
</script>