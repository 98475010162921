<template>
    <ContentLayout>
        <v-row v-if="!hasAccess">
            <v-col cols="12" lg="5" xl="6">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }" tile>
                    <v-img
                        color="primary" :height="$vuetify.breakpoint.lgAndUp ? '700px' : '350px'"
                        src="assets/img/gallery/18.jpg"
                    ></v-img>
                </v-card>
            </v-col>
            <v-col cols="12" lg="7" xl="6">
                <v-form ref="form" @submit.prevent @submit="submit">
                    <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }" tile color="secondary">
                        <v-card-title class="secondary-font primary--text text-h5 text-sm-h4 text-uppercase">
                            {{ $t("pages.hiring.info") }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="text-body-1">
                            <v-row dense>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.first_name')"
                                        v-model="form.first_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.last_name')"
                                        v-model="form.last_name"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="30"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.phone')"
                                        v-model="form.phone"
                                        v-mask="'(###) ###-####'"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.phone ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.email')"
                                        v-model="form.email"
                                        outlined
                                        :rules="[ $data.customRules.email ]" maxlength="200"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.nationality')"
                                        v-model="form.nationality"
                                        outlined
                                        :rules="[ $data.customRules.required ]" maxlength="20"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <!--<v-text-field
                                        :label="$t('pages.hiring.form.height')"
                                        v-model="form.height"
                                        v-mask="'###'"
                                        outlined suffix="cm"
                                        :rules="[ $data.customRules.required, $data.customRules.integer ]"
                                    ></v-text-field>-->
                                    <v-select
                                        :label="$t('pages.hiring.form.height')"
                                        v-model="form.height"
                                        :items="heights"
                                        outlined
                                        :rules="[ $data.customRules.required ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.weight')"
                                        v-model="form.weight"
                                        v-mask="'###'"
                                        outlined suffix="lbs"
                                        :rules="[ $data.customRules.required, $data.customRules.integer ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-select
                                        :label="$t('pages.hiring.form.eye_color')"
                                        v-model="form.eye_color"
                                        :items="[ 'blue', 'brown', 'gray', 'green', 'other' ]"
                                        outlined
                                        :rules="[ $data.customRules.required ]"
                                    >
                                        <template v-slot:selection="{ item }">
                                            {{ $t('eye_colors.' + item) }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ $t('eye_colors.' + item) }}
                                        </template>
                                    </v-select><!-- maxlength="10" -->
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-select
                                        :label="$t('pages.hiring.form.hair_color')"
                                        v-model="form.hair_color"
                                        :items="[ 'black', 'blond', 'brown', 'ginger', 'other' ]"
                                        outlined
                                        :rules="[ $data.customRules.required ]"
                                    >
                                        <template v-slot:selection="{ item }">
                                            {{ $t('hair_colors.' + item) }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            {{ $t('hair_colors.' + item) }}
                                        </template>
                                    </v-select><!-- maxlength="10" -->
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.age')"
                                        v-model="form.age"
                                        v-mask="'##'"
                                        outlined
                                        :rules="[ $data.customRules.required, $data.customRules.integer ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="3">
                                    <v-text-field
                                        :label="$t('pages.hiring.form.bra_size')"
                                        v-model="form.bra_size"
                                        v-mask="'##AA'"
                                        outlined
                                        :rules="[ $data.customRules.required ]"
                                        maxlength="4"
                                        :hint="$t('pages.hiring.form.bra_size_hint')" persistent-hint
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-textarea
                                        :label="$t('pages.hiring.form.experience')"
                                        v-model="form.experience"
                                        outlined counter="200"
                                        :rules="[ $data.customRules.required ]" maxlength="200"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-textarea
                                        :label="$t('pages.hiring.form.about_me')"
                                        v-model="form.about_me"
                                        outlined counter="200"
                                        :rules="[ $data.customRules.required ]" maxlength="200"
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <input type="file" multiple v-show="false" ref="photos" @change="addPhotos()" accept=".jpg,.jpeg,.png" />
                                    <v-btn block :large="$vuetify.breakpoint.smAndUp" :small="$vuetify.breakpoint.xs" text outlined tile @click="$refs.photos.click()">
                                        <v-icon left>mdi-camera</v-icon>{{ $t('pages.hiring.form.photos') }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" v-if="form.photos.length > 0">
                                    <v-row>
                                        <v-col
                                            cols="6" md="4"
                                            v-for="(photo, i) in form.photos" :key="i"
                                        >
                                            <v-img
                                                :src="'data:image/jpeg;base64,' + photo"
                                                height="200px"
                                            >
                                                <v-row>
                                                    <v-col class="text-right">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                    v-on="on" class="ma-2"
                                                                    color="error"
                                                                    fab tile
                                                                    @click="form.photos.splice(i, 1)"
                                                                ><v-icon>mdi-delete</v-icon></v-btn>
                                                            </template>
                                                            {{ $t("delete") }}
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                            </v-img>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" large outlined tile type="submit" :loading="submitting">
                                <v-icon left>mdi-send</v-icon>{{ $t('pages.hiring.submit.btn') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row v-else-if="fetching">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate size="80" color="secondary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="items.length > 0">
            <v-col cols="12">
                <v-text-field
                    :label="$t('search')"
                    prepend-inner-icon="mdi-magnify"
                    v-model="search"
                    outlined clearable hide-details
                    :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" xl="3" v-for="(item, i) in filteredItems" :key="i">
                <HostesseElement :item="item" origin="hiring" @update="fetch"></HostesseElement>
            </v-col>
        </v-row>
        <div
            v-if="hasAccess && (items.length === 0 || filteredItems.length === 0)"
            class="text-center secondary-font text-md-h4 text-sm-h5 text-h6"
        >
            {{ $t("no-data") }}
        </div>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import HostesseElement from "@/components/elements/Hostesse";

    export default {
        name: "hiring-view",
        components: { ContentLayout, HostesseElement },
        data: () => ({
            fetching: false,
            submitting: false,
            form: {
                first_name: null,
                last_name: null,
                phone: null,
                email: null,

                nationality: null,
                height: null,
                weight: null,
                eye_color: null,
                hair_color: null,
                age: null,
                bra_size: null,

                experience: null,
                about_me: null,

                photos: []
            },
            defaults: null,

            search: null,
            items: [],

            heights: [
                { value: "408", text: "< 4'8\"" },
                { value: "409", text: "4'9\"" },
                { value: "410", text: "4'10\"" },
                { value: "411", text: "4'11\"" },
                { value: "500", text: "5'0\"" },
                { value: "501", text: "5'1\"" },
                { value: "502", text: "5'2\"" },
                { value: "503", text: "5'3\"" },
                { value: "504", text: "5'4\"" },
                { value: "505", text: "5'5\"" },
                { value: "506", text: "5'6\"" },
                { value: "507", text: "5'7\"" },
                { value: "508", text: "5'8\"" },
                { value: "509", text: "5'9\"" },
                { value: "510", text: "5'10\"" },
                { value: "511", text: "5'11\"" },
                { value: "600", text: "6'0\"" },
                { value: "601", text: "6'1\"" },
                { value: "602", text: "6'2\"" },
                { value: "603", text: "6'3\"" },
                { value: "604", text: "6'4\"" },
                { value: "605", text: "> 6'5\"" }
            ]
        }),
        computed: {
            filteredItems() {
                return this.items.filter(i => this.search == null || 
                    (!!i.nickname && i.nickname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) || 
                    (!!i.first_name && i.first_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) || 
                    (!!i.last_name && i.last_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1));
            },

            hasAccess() {
                return !!this.$store.state.user && 
                    !!this.$store.state.user.type && 
                    this.$store.state.user.type == "admin";
            }
        },
        watch: {
            "$store.state.user": function() {
                this.fetch();
            },
        },
        methods: {
            fetch() {
                if(this.fetching || !this.hasAccess) {
                    return;
                }
                this.fetching = true;
                this._request("hostesses/get-all", { origin: "hiring" }).then(response => {
                    if(!response.items || response.items.length == 0) {
                        this.items = [];
                        return;
                    }
                    this.items = response.items;
                    //console.log(this.items);

                }).finally(() => {
                    this.fetching = false;
                });
            },
            submit() {
                if(this.submitting) {
                    return;
                }
                if(!this.$refs || !this.$refs.form || !this.$refs.form.validate() || this.form.photos.length === 0) {
                    this.$root.snack.show({
                        text: this.$i18n.t("form-validation-error"),
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                    return;
                }

                this.submitting = true;
                this._request("forms/hiring", this.form).then(response => {
                    if(response.error) {
                        this.$root.snack.show({
                            text: this.$i18n.t("pages.hiring.submit.error"),
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                        return;
                    }

                    this.form = JSON.parse(this.defaults);
                    this.$refs.form.resetValidation();

                    this.$root.snack.show({
                        text: this.$i18n.t("pages.hiring.submit.success"),
                        icon: "mdi-check",
                        color: "success"
                    });

                }).finally(() => {
                    this.submitting = false;
                });
            },

            addPhotos() {
                let files = this.$refs.photos && this.$refs.photos.files ? this.$refs.photos.files : [];
                if(files.length == 0) {
                    return;
                }

                for(let i = 0; i < files.length; i++) {
                    let img = new Image();
                    img.onload = () => {
                        // Resize
                        let canvas = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        let scale = 1080 / img.height;
                        canvas.width = img.width * scale;
                        canvas.height = img.height * scale;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        let data = canvas.toDataURL(files[i].type, 0.7);

                        this.form.photos.push(data.split("base64,")[1]);
                    };
                    img.src = window.URL.createObjectURL(files[i]);
                }
            }
        },
        created() {
            this.defaults = JSON.stringify(this.form);
            this.fetch();
        },
        activated() {
            this.fetch();
        }
    };
</script>
