<template>
    <div>
        <v-sheet color="secondary" height="200px">
            <v-row no-gutters class="fill-height background" align="center" justify="center">
                <v-col cols="auto" class="text-center secondary-font text-uppercase text-md-h2 text-sm-h3 text-h4">
                    {{ $t("pages." + $route.name + ".title") }}
                </v-col>
            </v-row>
        </v-sheet>
        <v-divider></v-divider>
        <v-container fluid class="px-md-16 py-md-8 pa-8 mb-8">
            <slot></slot>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "content-layout"
    };
</script>

<style scoped>
    .background::before{
        z-index: 2;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.5;

        background: url('@/assets/img/bg-01.jpg')
    }
    .background::after {
        z-index: 2;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.4;

        background: linear-gradient(-45deg, #f8cf52, transparent);
        background-size: 400% 400%;
        animation: gradient 90s ease infinite;
    }
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
</style>