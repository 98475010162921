<template>
    <v-dialog v-model="value" fullscreen hide-overlay persistent transition="fade-transition">
        <v-container fill-height fluid class="pa-0 ma-0 black" id="container">
            <div id="bg"></div>
            <v-row no-gutters id="content">
                <v-col cols="12" md="10">
                    <v-container fill-height fluid class="secondary-font">
                        <v-row align="center" justify="center">
                            <v-col cols="12" align="center">
                                <v-img src="@/assets/img/logo+text_dark.svg" id="logo"></v-img>
                            </v-col>
                            <v-col cols="12" class="my-8">
                                <v-row align="center" justify="center">
                                    <v-col cols="auto">
                                        <v-btn
                                            outlined tile large elevation="8"
                                            @click="enter('fr')"
                                        >Français</v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        |
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn
                                            outlined tile large elevation="8"
                                            @click="enter('en')"
                                        >English</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" align="center" class="text-caption text-uppercase">
                                <p>
                                    Ce site est destiné à un public adulte. 
                                    En accédant à ce site vous confirmez que vous êtes âgé de 18 ans et plus.
                                </p>
                                <p>
                                    This site is intended for an adult audience. 
                                    By accessing this site you confirm that you are 18 years of age or older.
                                </p>
                            </v-col>
                            <v-col cols="12" align="center" class="text-caption">
                                &copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-dialog>
</template>

<script>
    export default {
        name: "splash-dialog",
        components: {},
        data: () => ({
            value: true
        }),
        methods: {
            enter(locale) {
                //console.log(locale);
                this.value = false;
                this.$i18n.locale = locale;
                this.$vuetify.lang.current = locale;
                this.$emit("enter");
            }
        }
    };
</script>

<style scoped>
    #logo {
        max-width: 500px;
    }

    #container {
        position: relative;
    }

    #bg {
        position: absolute;
        z-index: 1;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        background-image: url("@/assets/img/splash.png");
        background-size: auto 100%;
        background-position-x: 0px;
    }

    #content {
        z-index: 2;
    }

    @media screen and (min-width: 960px) and (max-width: 1264px) { /* md */
        #bg {
            background-position-x: -200px;
        }
    }
    @media screen and (min-width: 600px) and (max-width: 960px) { /* sm */
        #bg {
            background-position-x: -300px;
        }
    }
    @media screen and (max-width: 600px) { /* xs */
        #logo {
            max-width: 350px;
        }
        #bg {
            opacity: 0.5;
            background-position-x: -750px;
        }
    }
</style>