<template>
    <div>
        <v-container fluid id="container">
            <video autoplay muted loop>
                <source src="@/assets/videos/intro.mp4" type="video/mp4">
            </video>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid class="px-md-16 py-md-8 pa-8 secondary-font">
            <v-row justify="center">
                <v-col cols="12" sm="3" align="center">
                    <v-icon color="primary" size="60">mdi-parking</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t('pages.home.free-parking') }}
                    </div>
                </v-col>
                <v-col cols="12" sm="3" align="center">
                    <v-icon color="primary" size="60">mdi-credit-card</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t('pages.home.credit-cards') }}
                    </div>
                </v-col>
                <v-col cols="12" sm="3" align="center">
                    <v-icon color="primary" size="60">mdi-cash</v-icon>
                    <div class="mt-4 text-uppercase">
                        {{ $t('pages.home.atm') }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container fluid class="px-md-16 py-md-8 pa-8 secondary-font secondary">
            <v-row justify="center">
                <v-col cols="12" align="center" class="main-font text-uppercase text-h5">
                    {{ $t('pages.home.text-1') }}
                </v-col>
                <v-col cols="12" align="center" class="secondary-font">
                    {{ $t('pages.home.text-2') }}
                </v-col>
            </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container
            fluid class="px-md-16 py-md-8 pa-8 mb-md-0 mb-8 secondary-font text-center"
        >
            <v-btn
                x-large outlined tile
                :block="$vuetify.breakpoint.xs"
                class="mr-sm-4 mb-sm-0 mb-4"
                :to="{ name: 'hostesses' }"
            >
                {{ $t('pages.home.hostesses') }}
            </v-btn>
            <v-btn
                x-large outlined tile
                :block="$vuetify.breakpoint.xs"
                color="primary"
                :to="{ name: 'schedule' }"
            >
                {{ $t('pages.home.schedule') }}
            </v-btn>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: "home-view"
    };
</script>

<style scoped>
    #container {
        position: relative;
        height: 100vh;
        padding: 0;
        overflow: hidden;
    }
    video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1920px;
        height: auto;
    }

    @media screen and (min-width: 960px) and (max-width: 1264px) { /* md */
        #container {
            height: 80vh;
        }
        video {
            width: 1400px;
        }
    }
    @media screen and (min-width: 600px) and (max-width: 960px) { /* sm */
        #container {
            height: 70vh;
        }
        video {
            width: 1200px;
        }
    }
    @media screen and (max-width: 600px) { /* xs */
        #container {
            height: 60vh;
        }
        video {
            width: 1000px;
        }
    }
</style>