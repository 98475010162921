<template>
    <ContentLayout>
        <v-row v-if="fetching">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate size="80" color="secondary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="images.length > 0" justify="center">
            <v-col cols="12" sm="6" md="5" v-for="(image, i) in images" :key="i">
                <v-card elevation="8" :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }" @click="openOverlay(image)" tile>
                    <v-img
                        color="primary" height="400"
                        :src="path + image"
                    ></v-img>
                </v-card>
            </v-col>
        </v-row>
        <div v-else class="text-center secondary-font text-md-h4 text-sm-h5 text-h6">
            {{ $t("no-data") }}
        </div>
        <ImageCarouselOverlay
            :value="overlay.value"
            :images="images"
            :current="overlay.current"
            :path="path"
            @close="overlay.value = false"
            cycle hide-delimiters
        ></ImageCarouselOverlay>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import ImageCarouselOverlay from "@/components/elements/ImageCarouselOverlay";

    export default {
        name: "gallery-view",
        components: { ContentLayout, ImageCarouselOverlay },
        data: () => ({
            fetching: false,
            images: [],
            overlay: {
                value: false,
                current: null
            },
            path: "./assets/img/gallery/"
        }),
        methods: {
            fetch() {
                if(this.fetching) {
                    return;
                }
                this.fetching = true;
                this._request("gallery/get-all").then(response => {
                    if(!response.images || response.images.length == 0) {
                        this.images = [];
                        return;
                    }
                    //console.log(response.images);
                    this.images = response.images;

                }).finally(() => {
                    this.fetching = false;
                });
            },
            openOverlay(currentImage) {
                this.overlay.current = currentImage;
                this.overlay.value = true;
            }
        },
        activated() {
            this.fetch();
        },
        created() {
            this.fetch();
        }
    };
</script>
