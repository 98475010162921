<template>
    <ContentLayout>
        <HostesseElement v-if="hasAccess" button @update="fetch"></HostesseElement>
        <v-row v-if="fetching">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate size="80" color="secondary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row v-else-if="items.length > 0" justify="center">
            <v-col cols="12">
                <v-text-field
                    :label="$t('search')"
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    outlined clearable hide-details
                    :class="{ 'mx-4 my-4': $vuetify.breakpoint.mdAndUp }"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4" xl="3" v-for="(item, i) in filteredItems" :key="i">
                <HostesseElement :item="item" @update="fetch"></HostesseElement>
            </v-col>
        </v-row>
        <div
            v-if="items.length === 0 || filteredItems.length === 0"
            class="text-center secondary-font text-md-h4 text-sm-h5 text-h6"
        >
            {{ $t("no-data") }}
        </div>
    </ContentLayout>
</template>

<script>
    import ContentLayout from "@/components/layouts/Content";

    import HostesseElement from "@/components/elements/Hostesse";

    export default {
        name: "hostesses-view",
        components: { ContentLayout, HostesseElement },
        data: () => ({
            fetching: false,
            search: null,
            items: []
        }),
        computed: {
            filteredItems() {
                return this.items.filter(i => this.search == null || 
                    (!!i.nickname && i.nickname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) || 
                    (!!i.first_name && i.first_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) || 
                    (!!i.last_name && i.last_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1));
            },

            hasAccess() {
                return !!this.$store.state.user && 
                    !!this.$store.state.user.type && 
                    (this.$store.state.user.type == "admin");
            }
        },
        watch: {
            "$store.state.user": function() {
                this.fetch();
            },
        },
        methods: {
            fetch() {
                if(this.fetching) {
                    return;
                }
                this.fetching = true;
                this._request("hostesses/get-all").then(response => {
                    if(!response.items || response.items.length == 0) {
                        this.items = [];
                        return;
                    }
                    this.items = response.items;
                    //console.log(this.items);

                }).finally(() => {
                    this.fetching = false;
                });
            },
        },
        created() {
            this.fetch();
        },
        activated() {
            this.fetch();
        }
    };
</script>