<template>
    <v-app>
        <v-navigation-drawer
            v-model="drawer"
            app :temporary="$vuetify.breakpoint.xs"
        >
            <div class="background-absolute"></div>
            <v-img src="@/assets/img/logo+text_dark.svg" height="100px" contain class="ma-4 my-12"></v-img>
            <div class="text-center mb-8">
                <LanguageElement></LanguageElement>
            </div>
            <v-divider></v-divider>
            <v-list tile dense class="text-uppercase main-font">
                <v-list-item
                    v-for="route in drawerRoutes"
                    :key="route.name"
                    link
                    :to="route.name"
                    class="mx-2 my-1"
                >
                    <v-list-item-icon>
                        <v-icon small>{{ route.meta.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t("pages." + route.name + ".title") }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-list tile dense class="text-uppercase">
                    <v-list-item
                        v-if="!$store.state.user"
                        @click="$root.dialogs.login.open()"
                        class="mx-2 my-1"
                    >
                        <v-list-item-icon>
                            <v-icon small>mdi-login</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("session.login.text") }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        v-else
                        @click="$root.dialogs.login.logout()"
                        class="mx-2 my-1"
                    >
                        <v-list-item-icon>
                            <v-icon small>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t("session.logout.text") }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>
        <v-main v-if="visible" class="black">
            <v-app-bar
                v-if="$vuetify.breakpoint.smAndDown"
                app height="120px" color="secondary"
            >
                <v-app-bar-nav-icon @click="drawer = !drawer" tile></v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <v-app-bar-title>
                    <router-link to="/">
                        <v-img src="@/assets/img/logo+text_dark.svg" height="auto" width="180px" contain></v-img>
                    </router-link>
                </v-app-bar-title>
                <v-spacer></v-spacer>
                <LanguageElement icon></LanguageElement>
            </v-app-bar>
            <router-view></router-view>
        </v-main>
        <v-footer v-if="visible" padless absolute app inset>
            <v-card class="flex main-font" flat tile>
                <v-card-title class="secondary">
                    <v-row no-gutters>
                        <v-col cols="12" md="3" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            <a :href="'tel:' + _env.VUE_APP_PHONE" class="white--text text-decoration-none" target="_blank">
                                <v-icon left>mdi-phone</v-icon> {{ _env.VUE_APP_PHONE }}
                            </a>
                        </v-col>
                        <v-col cols="12" md="6" class="text-center" :class="{ 'my-4': $vuetify.breakpoint.smAndDown }">
                            <a :href="_env.VUE_APP_ADDRESS_LINK" class="white--text text-decoration-none" target="_blank">
                                <v-icon left>mdi-map-marker</v-icon> {{ _env.VUE_APP_ADDRESS }}
                            </a>
                        </v-col>
                        <v-col cols="12" md="3" class="text-right" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            <v-tooltip top v-for="link in links" :key="link.value">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-on="on" v-bind="attrs"
                                        class="mx-4" icon tile
                                        :href="link.value"
                                        target="_blank"
                                    >
                                        <v-icon size="26px">{{ link.icon }}</v-icon>
                                    </v-btn>
                                </template>
                                {{ link.text }}
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="py-2 accent text-caption">
                    <v-row no-gutters>
                        <v-col cols="12" class="text-center">
                            &copy; {{ new Date().getFullYear() }} {{ _env.VUE_APP_TITLE }} — {{ $t("copyright") }}
                        </v-col> <!-- md="6" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }" -->
                        <!--<v-col cols="12" md="6" class="text-right" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                            {{ $t("developed_by") }} <a class="text-decoration-none white--text" :href="_env.VUE_APP_AUTHOR_WEBSITE" target="_blank">{{ _env.VUE_APP_AUTHOR_NAME }}</a>
                        </v-col>-->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-footer>
        <SplashDialog @enter="updateSplash()"></SplashDialog>
        <LoginDialog ref="loginDialog"></LoginDialog>
        <SnackElement ref="snackElement"></SnackElement>
        <ImageCarouselOverlay
            :value="promos.overlay"
            :images="promos.images"
            @close="promos.overlay = false"
            path="./uploads/"
            cycle
        ></ImageCarouselOverlay>
    </v-app>
</template>

<script>
    import SplashDialog from "./dialogs/Splash";
    import LoginDialog from "./dialogs/Login";
    import SnackElement from "./elements/Snack";
    import LanguageElement from "./elements/Language";
    import ImageCarouselOverlay from "@/components/elements/ImageCarouselOverlay";

    export default {
        name: "app",
        components: {
            SplashDialog, LoginDialog, SnackElement, LanguageElement,
            ImageCarouselOverlay
        },
        data: () => ({
            visible: false,
            drawer: true,
            login: false,

            links: [
                { icon: "mdi-twitter", text: "Twitter", value: "https://x.com/spamarquise" },
                { icon: "mdi-instagram", text: "Instagram", value: "https://instagram.com/spamarquise" }
            ],

            promos: {
                overlay: false,
                images: []
            }
        }),
        computed: {
            drawerRoutes() {
                return this.$router.options.routes.filter(route => !!route.meta);
            }
        },
        methods: {
            updateSplash() {
                this.visible = true;

                // fetch and show promos
                this._request("promotions/get-all", { home: true }).then(response => {
                    if(!response.images || response.images.length == 0) {
                        this.promos.images = [];
                        return;
                    }
                    this.promos.images = response.images;
                    this.promos.overlay = true;
                });

                // update page for schedule on mobile only
                if(this.$vuetify.breakpoint.smAndDown) {
                    this.$router.push({ name: 'schedule' });
                }
            }
        },
        created() {
            // check session
            this._request("users/session").then(response => {
                if(!response.user) {
                    return;
                }
                this.$store.commit({ type: "setUser", user: response.user });
            });
            this.drawer = this.$vuetify.breakpoint.name != "xs" && this.$vuetify.breakpoint.name != "sm";
        },
        mounted() {
            this.$root.dialogs = {
                login: this.$refs.loginDialog
            };
            this.$root.snack = this.$refs.snackElement;
        }
    };
</script>

<style>
    @font-face {
        font-family: 'manrope';
        src: url('@/assets/fonts/manrope.ttf') format('truetype-variations');
        font-weight: 200 800;
    } /* https://fonts.google.com/specimen/Manrope?preview.text=SPA%20MARQUISE */
    .main-font {
        font-family: 'manrope' !important;
        font-weight: 400 !important;
    }
    .secondary-font {
        font-family: 'manrope' !important;
        font-weight: 200 !important;
    }
    body {
        font-family: 'manrope' !important;
        font-weight: 300 !important;
    }

    .background-absolute {
        z-index: 0;
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
    }
    .background {
        position: relative;
    }
    .background::after, .background-absolute::after {
        z-index: 1;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 1;

        background: linear-gradient(#000 0%, #141213 50%);
        background-color: #000;
    }
    /*.background::before, .background-absolute::before {
        z-index: 2;

        content: "";
        position: absolute;
        top: 0px; bottom: 0px; left: 0px; right: 0px;
        opacity: 0.8;

        background: linear-gradient(180deg, rgba(0,0,0,0) 60%, #000 100%);
    }*/
    .background > *, .background-absolute > * {
        z-index: 3;
    }
    .payments {
        display: inline-block;
        height: auto;
        width: 120px;
    }
</style>